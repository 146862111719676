import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import * as Sentry from '@sentry/browser';

type FingerprintConfig = {
  enabled: boolean;
  sessionId: string;
  apiKey: string;
};

export const initializeFingerprint = async ({
  enabled = false,
  sessionId,
  apiKey,
}: FingerprintConfig) => {
  if (enabled) {
    try {
      const fp = await FingerprintJS.load({
        apiKey,
        region: 'eu',
        scriptUrlPattern: [
          'https://fp.billie.io/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
          FingerprintJS.defaultScriptUrlPattern,
        ],
        endpoint: ['https://fp.billie.io', FingerprintJS.defaultEndpoint],
      });

      const fpResult = await fp.get({ linkedId: sessionId });

      Sentry.addBreadcrumb({
        category: 'Fingerprint',
        level: 'info',
        message: 'Fingerprinting successful',
        data: {
          visitorId: fpResult.visitorId,
        },
      });
    } catch (err) {
      console.error('Could not initialize fingerprintjs, skipping...');
      Sentry.captureException(err, { level: 'error' });
    }
  }
};
